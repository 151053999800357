import { useCallback } from 'react';
import { useHistory, generatePath, useParams, useLocation } from 'react-router-dom';

import Dialog, { DialogHeader, DialogContent, DialogFooter } from '@guestyci/foundation/Dialog';
import FlatButton from '@guestyci/foundation/FlatButton';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import t from '@guestyci/localize/t.macro';
import createStyles from '@guestyci/foundation/createStyles';

import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

const useStyles = createStyles(() => ({
  root: {
    maxWidth: 512,
    minWidth: 300,
  },
}));

const ERROR_TYPES = {
  SESSION_EXPIRED: [
    'Inquiry has already expired',
    'Inquiry is already fulfilled by a reservation',
    'Inquiry was not found',
  ],
  AVAILABILITY: ['There is not availability to create this reservation', 'There is no consecutive availability'],
  RATE_PLAN: ['Requested rate plan does not exist'],
};

const matchErrorMessage = (error, patterns) => {
  const normalizedError = error.toLowerCase();
  return patterns.some((pattern) => normalizedError.includes(pattern.toLowerCase()));
};

const ErrorDialogWrapper = ({ open, handleClose, children }) => {
  const { root } = useStyles();
  return (
    <Dialog className={root} open={open} onClose={handleClose} noClear>
      {children}
    </Dialog>
  );
};

export const SessionExpiredDialog = ({ open, handleClose }) => {
  const { getPathWithLocale } = useGetPathToNavigate();
  const history = useHistory();

  const redirectPath = getPathWithLocale('/properties');
  const title = t('Session has ended');
  const description = t('The session has ended due to inactivity. Please restart your search.');

  const handleClick = useCallback(() => {
    handleClose();
    history.push({
      pathname: redirectPath,
      search: '',
    });
  }, [handleClose, history, redirectPath]);

  return (
    <ErrorDialogWrapper open={open} handleClose={handleClose}>
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>{description}</DialogContent>
      <DialogFooter>
        <RaisedButton onClick={handleClick}>{t('Restart search')}</RaisedButton>
      </DialogFooter>
    </ErrorDialogWrapper>
  );
};

export const DatesNotAvailableDialog = ({ open, handleClose, checkOutData = {} }) => {
  const {
    property: { title: propertyName = 'Property' } = {},
    checkInDateLocalized,
    checkOutDateLocalized,
  } = checkOutData || {};
  const history = useHistory();
  const { getPathWithLocale } = useGetPathToNavigate();
  const { id } = useParams();

  const title = t("The dates you picked aren't available");
  const description = t(
    '{propertyName} is no longer available on {dateRange}. Please change the dates or look for a different property',
    {
      propertyName,
      dateRange: `${checkInDateLocalized} - ${checkOutDateLocalized}`,
    }
  );

  const handleClick = useCallback(() => {
    handleClose();
    const redirectPath = getPathWithLocale('/properties');
    const pathname = generatePath(`${redirectPath}/:id`, { id });
    history.push({
      pathname,
    });
  }, [getPathWithLocale, handleClose, history, id]);

  const handleCloseClick = useCallback(() => {
    handleClose();
    const redirectPath = getPathWithLocale('/properties');
    history.push({
      pathname: redirectPath,
    });
  }, [getPathWithLocale, handleClose, history]);

  return (
    <ErrorDialogWrapper open={open} handleClose={handleClose}>
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>{description}</DialogContent>
      <DialogFooter>
        <FlatButton onClick={handleCloseClick}>{t('Restart search')}</FlatButton>
        <RaisedButton onClick={handleClick}>{t('Change dates')}</RaisedButton>
      </DialogFooter>
    </ErrorDialogWrapper>
  );
};

export const RatePlanNotAvailableDialog = ({ open, handleClose, checkOutData = {} }) => {
  const { property: { title: propertyName = 'Property' } = {} } = checkOutData || {};
  const history = useHistory();
  const { search } = useLocation();
  const { getPathWithLocale } = useGetPathToNavigate();
  const { id } = useParams();

  const title = t('That price is no longer available');
  const description = t('{propertyName} is no longer available for that price.', {
    propertyName,
  });

  const handleClick = useCallback(() => {
    handleClose();
    const redirectPath = getPathWithLocale('/properties');
    const pathname = generatePath(`${redirectPath}/:id`, { id });
    history.push({
      pathname,
      search,
    });
  }, [getPathWithLocale, handleClose, history, id, search]);
  return (
    <ErrorDialogWrapper open={open} handleClose={handleClose}>
      <DialogHeader>{title}</DialogHeader>
      <DialogContent>{description}</DialogContent>
      <DialogFooter>
        <RaisedButton onClick={handleClick}>{t('Reload page')}</RaisedButton>
      </DialogFooter>
    </ErrorDialogWrapper>
  );
};

const ReservationErrorDialog = ({ error = null, open = false, handleClose = () => {}, checkOutData = {} } = {}) => {
  if (error && typeof error === 'string') {
    // Handle session expired related errors
    if (matchErrorMessage(error, ERROR_TYPES.SESSION_EXPIRED)) {
      return <SessionExpiredDialog open={open} handleClose={handleClose} />;
    }

    // Handle availability related errors
    if (matchErrorMessage(error, ERROR_TYPES.AVAILABILITY)) {
      return <DatesNotAvailableDialog open={open} handleClose={handleClose} checkOutData={checkOutData} />;
    }

    // Handle package/rate plan related errors
    if (matchErrorMessage(error, ERROR_TYPES.RATE_PLAN)) {
      return <RatePlanNotAvailableDialog open={open} handleClose={handleClose} />;
    }
  }

  // Default to session expired dialog for unspecified errors
  return <SessionExpiredDialog open={open} handleClose={handleClose} />;
};

export default ReservationErrorDialog;
