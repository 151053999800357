import { useMemo } from 'react';

import useGetListingPaymentProvider from 'hooks/useGetListingPaymentProvider';
import useGetGroupPaymentProvider from 'hooks/useGetGroupPaymentProvider';
import useIsGroupReservation from 'hooks/useIsGroupReservation';

const useListingPaymentProvider = ({ listingId }) => {
  const { isGroupReservation } = useIsGroupReservation();

  const {
    data: listingPaymentProvider,
    isLoading: isListingPPLoading,
    error: listingError
  } = useGetListingPaymentProvider({
    listingId,
    enabled: !isGroupReservation,
  });

  const {
    data: groupPaymentProvider,
    isLoading: isGroupPPLoading,
    error: groupError
  } = useGetGroupPaymentProvider({
    enabled: isGroupReservation,
  });

  const error = groupError || listingError;
  const isLoading = isGroupPPLoading || isListingPPLoading;

  return useMemo(() => {
    const {
      providerAccountId,
      providerType,
      _id: providerId,
    } = (isGroupReservation ? groupPaymentProvider : listingPaymentProvider) || {};

    return {
      isLoading,
      error,
      providerAccountId,
      providerType,
      providerId,
    };
  }, [
    isLoading,
    error,
    groupPaymentProvider,
    listingPaymentProvider,
    isGroupReservation
  ]);
};

export default useListingPaymentProvider;
