import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { APIProvider, Map } from '@vis.gl/react-google-maps';

import CustomAdvancedMarker from './CustomAdvancedMarker';
import { GOOGLE_MAP_KEY } from 'constants/map';
const DEBOUNCE_TIME = 700;

function getBounds(map) {
  if (!map) return null;

  const bounds = map.getBounds();
  if (!bounds) return null;

  const ne = bounds.getNorthEast(); // Top-right corner
  const sw = bounds.getSouthWest(); // Bottom-left corner

  return {
    minLat: sw.lat(), // Southernmost latitude
    maxLat: ne.lat(), // Northernmost latitude
    minLng: sw.lng(), // Westernmost longitude
    maxLng: ne.lng()  // Easternmost longitude
  };
}


function boundsChangedSignificantly(prevBounds, newBounds) {
  if (!prevBounds || !newBounds) return true;

  const latDiff = Math.abs(((newBounds.maxLat - newBounds.minLat) - (prevBounds.maxLat - prevBounds.minLat)) /
    (prevBounds.maxLat - prevBounds.minLat));

  const lngDiff = Math.abs(((newBounds.maxLng - newBounds.minLng) - (prevBounds.maxLng - prevBounds.minLng)) /
    (prevBounds.maxLng - prevBounds.minLng));
  return latDiff > 0.1 || lngDiff > 0.1; // 10% threshold
}


const MapContainer = ({ setSelectedBounds, listings, hoveredListingId }) => {
  const previousBoundsRef = useRef(null);

  const debouncedBoundsUpdate = useCallback(
    debounce((map) => {
      const newBounds = getBounds(map);
      if (newBounds && boundsChangedSignificantly(previousBoundsRef.current, newBounds)) {
        console.log('bounds changed significantly', newBounds);
        setSelectedBounds(newBounds);
        previousBoundsRef.current = newBounds;
      }
    }, DEBOUNCE_TIME),
    [setSelectedBounds]
  );

  useEffect(() => {
    return () => {
      debouncedBoundsUpdate.cancel();
    };
  }, [debouncedBoundsUpdate]);

  const onDragEnd = useCallback((map) => {
    if (!map) return;
    debouncedBoundsUpdate(map);
  }, [debouncedBoundsUpdate]);


  const generateMarkers = useCallback(() => {
    return listings?.markers?.map((marker) => {
      return (
        <CustomAdvancedMarker
          key={JSON.stringify(marker)}
          marker={marker}
          hoveredListingId={hoveredListingId}
        />
      )
    })
  }, [listings, hoveredListingId])

  return (
    <APIProvider apiKey={GOOGLE_MAP_KEY} libraries={['marker']}>
      <Map
        mapId="coolMap"
        style={{ width: '100%', height: '100%' }}
        defaultCenter={{ lat: 22.54992, lng: 0 }}
        defaultZoom={3}
        gestureHandling={'greedy'}
        disableDefaultUI
        onBoundsChanged={(event) => {
          const { map } = event;
          onDragEnd(map)
        }}
      >
        {generateMarkers()}
      </Map>
    </APIProvider>
  );
}

export default MapContainer;