
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';
import Divider from '@guestyci/foundation/Divider';
import { Row } from '@guestyci/foundation/Layout';

import HotelFeatures from 'components/HotelFeatures';
import Map from 'components/MapContainer';
import Details from './Details';
import CheckInCheckOut from './CheckInCheckOut';
import AmenitiesSection from './AmenitiesSection';
import HouseRulesSection from './HouseRulesSection';
import BedArrangementsSection from './BedArrangementsSection';
import FullDescriptionSection from './FullDescriptionSection';

const useStyles = createStyles(({
  breakpoints: { create }
}) => ({
  propertyDescription: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    justifyContent: 'center',
    [create('md')]: {
      maxWidth: 550,
    },
    [create('lg')]: {
      maxWidth: 700,
    },
  },

}));

const Description = ({ property = {} }) => {
  const {
    propertyDescription,
  } = useStyles();

  return (
    <div data-property-description className={propertyDescription}>
      <TextField variant="h1">
        {property?.title}
      </TextField>
      <TextField color="secondary">{property?.subTitle}</TextField>
      <TextField bold variant="h4" className="mt-4">
        {t('Description')}
      </TextField>
      <Details property={property} />
      <CheckInCheckOut property={property} />
      <Divider className="mt-6 mb-6" />
      <div>
        <TextField bold className="mb-2">
          {t('Property features')}
        </TextField>
        <Row>
          <HotelFeatures
            beds={property?.beds}
            bathrooms={property?.bathrooms}
            bedrooms={property?.bedrooms}
            guests={property?.guests}
          />
        </Row>
      </div>
      <AmenitiesSection property={property} />
      <HouseRulesSection property={property} />
      <BedArrangementsSection property={property} />
      <FullDescriptionSection property={property} />
      <Map circle center={property?.address} />
    </div>
  );
}

export default Description;