import { useHistory, useLocation } from 'react-router-dom';

import Dialog, { DialogContent, DialogFooter } from '@guestyci/foundation/Dialog';
import { Section } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import FlatButton from '@guestyci/foundation/FlatButton';
import t from '@guestyci/localize/t.macro/t.macro';

import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import useSearchValues from 'hooks/useSearchValues';

export const ERROR_TYPES = {
  PROCESSOR_ERROR: 'PROCESSOR_ERROR',
  CHARGE_ERROR: 'CHARGE_ERROR',
};

const ProcessorErrorDialog = ({ open, message, handleClose = () => {} }) => {
  const titleLabel = t('Payment process failed');
  const errorMessage = message || t('Please try again or provide a different payment method');

  return (
    <Dialog open={open} onClose={handleClose}>
      <Section gutter="6 8 0 6">
        <TextField color="error" variant="h2">
          {titleLabel}
        </TextField>
      </Section>
      <DialogContent>
        <TextField>{errorMessage}</TextField>
      </DialogContent>
      <DialogFooter>
        <FlatButton onClick={handleClose}>{t('Ok')}</FlatButton>
      </DialogFooter>
    </Dialog>
  );
};

const ChargeErrorDialog = ({ open, message, handleClose = () => {} }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { listingId } = useSearchValues();
  const { getPathWithLocale } = useGetPathToNavigate();

  const titleLabel = t('Payment process failed');
  const errorMessage = message || t('Please try again or provide a different payment method');

  const handleClick = () => {
    handleClose();
    history.push({
      pathname: getPathWithLocale(`/properties/${listingId}`),
      search,
    });
  };

  return (
    <Dialog open={open} noClear>
      <Section gutter="6 8 0 6">
        <TextField color="error" variant="h2">
          {titleLabel}
        </TextField>
      </Section>
      <DialogContent>
        <TextField>{errorMessage}</TextField>
      </DialogContent>
      <DialogFooter>
        <FlatButton onClick={handleClick}>{t('Reload page')}</FlatButton>
      </DialogFooter>
    </Dialog>
  );
};

const PaymentErrorDialog = ({ error = null, open = false, handleClose = () => {} } = {}) => {
  if (!error) return null;

  if (error.type === ERROR_TYPES.CHARGE_ERROR) {
    return <ChargeErrorDialog open={open} handleClose={handleClose} message={error.message} />;
  }

  return <ProcessorErrorDialog open={open} handleClose={handleClose} message={error.message} />;
};

export default PaymentErrorDialog;
