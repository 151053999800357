import { useParams } from 'react-router-dom';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';
import useGetListingById from './useGetListingById';
import useSearchValues from './useSearchValues';

const MULTI_UNIT_PROPERTY_TYPE = 'MTL';

const useIsGroupReservation = ({ property: propertyProp = null } = {}) => {
  const [isGroupReservationFTLoading, isGroupReservationEnabled] = useFeatureToggle(GROUP_RESERVATIONS);
  const { id } = useParams();
  const { rooms } = useSearchValues();
  const { property: fetchedProperty, isGettingListing } = useGetListingById({
    id,
    enabled: !propertyProp && !!id,
  });

  const property = propertyProp || fetchedProperty;
  const isLoading = isGroupReservationFTLoading || (!propertyProp && isGettingListing);
  const isGroupReservation = isGroupReservationEnabled && property?.type === MULTI_UNIT_PROPERTY_TYPE && rooms > 1;

  return {
    isGroupReservationEnabled,
    isLoading,
    isGroupReservation,
  };
};

export default useIsGroupReservation;
