import TextField from "@guestyci/foundation/TextField";
import { Row } from "@guestyci/foundation/Layout";
import t from "@guestyci/localize/t.macro";
import Divider from "@guestyci/foundation/Divider";

import BedArrangement from "components/BedArrengement";

const BedArrangementsSection = ({ property = {} } = {}) => {
  const { bedArrangements: { bedrooms = [] } = {} } = property || {};
  if (!bedrooms?.length) return null;
  return (
    <div>
      <Divider className="mt-6 mb-6" />
      <TextField bold className="mb-2">
        {t('Room arrangement')}
      </TextField>
      <Row>
        <BedArrangement bedrooms={bedrooms} listingId={property?._id} />
      </Row>
    </div>
  );
}

export default BedArrangementsSection;