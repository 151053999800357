import { useContext, useEffect } from 'react';

import useGetCurrencyRate from 'hooks/useGetCurrencyRate';
import { getPriceStr } from 'utils';
import { CurrencyContext } from 'context/CurrencyContext';

const PriceConverter = ({ currency, amount, roundPrice = 0 }) => {
  const { selectedCurrency } = useContext(CurrencyContext);
  const {
    fetchHandler: getCurrencyRate,
    isSuccess,
    data,
  } = useGetCurrencyRate({
    from: currency,
    to: selectedCurrency,
  });
  const calculatedAmount = isSuccess ? data?.rate * amount : amount;

  useEffect(() => {
    if (currency && selectedCurrency && selectedCurrency !== currency) {
      getCurrencyRate({ from: currency, to: selectedCurrency });
    }
  }, [currency, selectedCurrency, getCurrencyRate]);

  const price = roundPrice ? calculatedAmount?.toFixed(roundPrice) : Math.round(calculatedAmount);

  return <span>{getPriceStr(price, selectedCurrency)}</span>;
};

export default PriceConverter;
