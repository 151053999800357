import { useStripe, useElements } from '@stripe/react-stripe-js';

import useSearchValues from './useSearchValues';
import useSetupIntents from './useSetupIntents';

const buildBillingDetailsObj = ({
  billingAddressCity = null,
  billingAddressCountry = null,
  billingAddressState = null,
  billingAddressStreet = null,
  billingAddressZipCode = null,
  cardHolderFirstName,
  cardHolderLastName,
  cardHolderNameAsGuest = false,
  firstName,
  lastName,
  phone,
  email,
}) => ({
  name: cardHolderNameAsGuest ? `${cardHolderFirstName} ${cardHolderLastName}` : `${firstName} ${lastName}`,
  address: {
    line1: billingAddressStreet,
    line2: null,
    postal_code: billingAddressZipCode,
    country: billingAddressCountry,
    city: billingAddressCity,
    state: billingAddressState,
  },
  phone,
  email,
});

const useHandleStripe = () => {
  const { listingId } = useSearchValues();
  const stripe = useStripe();
  const elements = useElements();
  const { mutateAsync: createStripeSetupIntent } = useSetupIntents();

  const confirmCardSetup = async ({ values }) => {
    if (!stripe || !elements) {
      // TODO: handle error
      throw new Error({
        message: 'Stripe is not initialized',
      });
    }

    const cardElement = elements.getElement('cardNumber');

    const intents = await createStripeSetupIntent(listingId);

    return stripe.confirmCardSetup(intents.client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: buildBillingDetailsObj(values),
      },
    });
  };

  const createConfirmationToken = async ({ values }) => {
    if (!stripe || !elements) {
      throw new Error({
        message: 'Stripe is not initialized',
      });
    }

    const { error: submitError } = await elements.submit();
    if (submitError) return { error: submitError };

    return stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: buildBillingDetailsObj(values),
        },
      },
    });
  };

  const handleNextAction = async ({ clientSecret }) => {
    if (!stripe) {
      throw new Error({
        message: 'Stripe is not initialized',
      });
    }

    return stripe.handleNextAction({ clientSecret });
  };

  return {
    confirmCardSetup,
    createConfirmationToken,
    handleNextAction,
  };
};

export default useHandleStripe;
