/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import warning from 'fbjs/lib/warning';

import createStyles from '@guestyci/foundation/createStyles';
import IconFoundation from '@guestyci/foundation/Icon';

import iconMap from 'assets/icons/iconMap';

const useStyles = createStyles(() => ({
  root: {
    width: '1em',
    height: '1em',
    fontSize: ({ fontSize }) => fontSize,
  },
}));

const Icon = ({ icon, className, size, onClick }) => {
  const { root } = useStyles({ fontSize: size });

  const IconComponent = iconMap[icon];

  if (!IconComponent) return null;
  return <IconFoundation fillColor="none" svg={IconComponent} className={cn(root, className)} onClick={onClick} />;
};

export default Icon;
