import moment from 'moment';

import t from '@guestyci/localize/t.macro';
import { Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

import useGetPaymentSchedule from 'hooks/useGetPaymentSchedule';
import { US_DATE_FORMAT } from 'constants/date';
import PriceConverter from 'components/PriceConverter';
import { WORKFLOW_TOOLTIP_TYPES } from 'constants/constants';
import PaymentClarificationTooltip from './PaymentClarificationTooltip';

const useStyles = createStyles(({ palette, spacer }) => ({
  root: {
    backgroundColor: palette.primary.backgroundLight,
    padding: spacer(2),
    marginBottom: spacer(6),
    marginTop: spacer(3),
  },
}));

const Holds = ({ total, roundPrice }) => {
  const classes = useStyles();
  const { data: { holds } = {} } = useGetPaymentSchedule({
    total,
  });

  if (!holds?.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      {holds.map((hold, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Row justify="between" key={`${hold?.date}-${i}`}>
            <Row align="center" spacing={2}>
              <TextField>
                {t('Amount held on {date}', {
                  date: moment(hold?.date).format(US_DATE_FORMAT),
                })}
              </TextField>
              <PaymentClarificationTooltip
                tooltip={{
                  holdAfter: hold?.holdAfter,
                  captureAfter: hold?.captureAfter,
                  holdDate: hold?.holdDate,
                  captureDate: hold?.captureDate,
                  duration: hold?.duration,
                  type: WORKFLOW_TOOLTIP_TYPES.DURATION_HOLD,
                }}
              />
            </Row>
            <PriceConverter roundPrice={roundPrice} currency={hold?.currency} amount={hold?.amount} />
          </Row>
        );
      })}
    </div>
  );
};

export default Holds;
