import { useRef, useEffect, useState } from 'react';
import cn from 'classnames';

import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import Button from '@guestyci/foundation/Button';
import t from '@guestyci/localize/t.macro';

import Image from 'components/Image/Image';
import PriceConverter from 'components/PriceConverter';
import useIsMobile from 'hooks/useIsMobile';
import useDio from 'hooks/useDio';
import { useUpsellContext } from 'context/UpsellContext';
import Counter from './Counter';
import useIsOverflow from '../../hooks/useIsOverflow';

// was calculated by divide row char length on row px size in Figma
const PX_PER_CHAR = 6;
const CARD_ROWS_COUNT = 4;
const MIN_EXPANDED_WIDTH = 315;

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  cardWrapper: {
    overflow: 'hidden',
    border: 'solid #D1D5DB 1px',
    borderRadius: 8,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    marginBottom: 10,
  },
  mobileImg: {
    height: '106px !important',
  },
  normal: {
    animationFillMode: 'forwards',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease',
    [create('md')]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 180,
    },
    [create('xs')]: {
      width: 180,
    },
  },
  closeAnimation: {
    animationName: '$closeAnimation',
  },

  closeAnimationImage: {
    animationName: '$closeAnimationImage',
  },

  expand: {
    animationFillMode: 'forwards',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease',
    animationName: '$openAnimation',
    [create('md')]: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'calc(var(--container-width, 315px))',
    },
    [create('xs')]: {
      width: 'calc(var(--container-width, 315px))',
    },
  },
  imageWrapper: {
    height: 130,
  },
  imageContainer: {
    position: 'relative',
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  imageCover: {
    textAlign: 'center',
    width: 180,
    height: 130,
    objectFit: 'cover',
    zIndex: 2,
    animationFillMode: 'forwards',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease',
  },
  imageBackground: {
    width: '100%',
    height: 130,
    objectFit: 'cover',
    zIndex: 1,
    filter: 'blur(3px)',
  },
  title: {
    padding: '10px 10px 0px 10px',
  },
  disc: {
    fontSize: 12,
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
    marginTop: 'auto',
  },
  btnColor: {
    color: '#206CFF',
  },
  ellipsis: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 'calc(var(--line-clamp, 3))',
    overflow: 'hidden',
    position: 'relative',
    margin: 0,
  },
  btnSmall: {
    display: 'inline-block',
    fontSize: 12,
    pointerEvents: 'auto',
  },
  '@keyframes openAnimationImage': {
    '0%': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, 0) scale(0.9)',
      borderRadius: 6,
    },
    '100%': {
      top: '0',
      left: '0',
    },
  },
  '@keyframes closeAnimationImage': {
    '0%': {
      top: '0',
      left: '0',
    },
    '100%': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, 0) scale(0.9)',
      borderRadius: 6,
    },
  },
  '@keyframes openAnimation': {
    '0%': {
      flexBasis: 180,
    },
    '100%': {
      flexBasis: 'calc(var(--container-width, 315px))',
    },
  },
  '@keyframes closeAnimation': {
    '0%': {
      flexBasis: 'calc(var(--container-width, 315px))',
    },
    '100%': {
      flexBasis: 180,
    },
  },
}));

const replaceMarkdownWithHtmlLinks = (text) => {
  // Regular expression to match Markdown links
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

  // Split the text into parts based on the regex
  const parts = [];
  let lastIndex = 0;
  text.replace(markdownLinkRegex, (match, linkText, url, offset) => {
    // Add the text before the match
    if (offset > lastIndex) {
      parts.push(text.slice(lastIndex, offset));
    }
    // Add the link as a React element
    parts.push(<a key={offset} href={url} target="_blank" rel="noopener noreferrer">
        {linkText}
      </a>);
    lastIndex = offset + match.length;
  });

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

const getImage = (list) => {
  const defaultImg = list.find((item) => item.isDefault);
  if (defaultImg) return defaultImg.url;
  return list.length > 0 ? list[0].url : '';
};

const calculateCardExpandedWidth = (size) => {
  if (!size) return 315;
  const charactersPerRow = Math.round(size / CARD_ROWS_COUNT);
  return charactersPerRow * PX_PER_CHAR < MIN_EXPANDED_WIDTH ? MIN_EXPANDED_WIDTH : charactersPerRow * PX_PER_CHAR;
};

const CardBody = ({ item, onReadmoreClick, isExpanded = false, currency, source, cardRef, itemRef }) => {
  const {
    _id: upsellId,
    upsell: { description, images },
    name,
    price,
  } = item;

  const descriptionContainerRef = useRef(null);
  const isOverflow = useIsOverflow(descriptionContainerRef, []);

  const {
    closeAnimationImage,
    imageContainer,
    imageCover,
    imageBackground,
    imageWrapper,
    title,
    btnSmall,
    disc,
    bottom,
    btnColor,
    mobileImg,
    ellipsis,
    card,
  } = useStyles();

  const isMobile = useIsMobile();

  const { dioTrack } = useDio();

  const { getUpsellItemCountForListingById, setUpsellItemCountForListingById } = useUpsellContext();

  const count = getUpsellItemCountForListingById(upsellId);

  const handleCount = (value) => {
    setUpsellItemCountForListingById(item, value);
  };

  const trackAdd = (upsellCount) => {
    dioTrack('click_got_upsell', 'click', { upsell: item, source, count: upsellCount });
  };

  const trackRemove = (upsellCount) => {
    dioTrack('click_remove_upsell', 'click', { upsell: item, source, count: upsellCount });
  };

  const trackAddButton = (upsellCount) => {
    dioTrack('click_got_upsell', 'click', { upsell: item, source, count: upsellCount });
    handleCount(upsellCount);
  };

  useEffect(() => {
    if (cardRef?.current) {
      cardRef.current.style.setProperty(
        '--container-width',
        `${calculateCardExpandedWidth(descriptionContainerRef.current?.innerText.length)}px`
      );
    }
  }, [descriptionContainerRef.current?.innerText, cardRef]);

  useEffect(() => {
    if (descriptionContainerRef?.current) {
      descriptionContainerRef.current.style.setProperty('--line-clamp', `${isMobile ? 1 : 3}`);
    }
  }, [isMobile]);

  const bottomSection =
    count > 0 ? (
      <Counter max={1} min={0} value={count} onChange={handleCount} onAdd={trackAdd} onRemove={trackRemove} />
    ) : (
      <Button className={btnColor} onClick={() => trackAddButton(1)}>
        {t('Add')}
      </Button>
    );

  return (
    <div className={card} ref={itemRef}>
      <div className={imageWrapper}>
        {isMobile ? (
          <Image alt="property" src={getImage(images)} className={mobileImg} />
        ) : (
          <div className={imageContainer}>
            <Image
              className={cn(imageCover, { [closeAnimationImage]: isExpanded })}
              alt="property"
              src={getImage(images)}
            />
            <Image className={imageBackground} alt="property" src={getImage(images)} />
          </div>
        )}
      </div>
      <div className={title}>
        <TextField bold>{name}</TextField>
        <div>
          <TextField ref={descriptionContainerRef} color="default" className={cn(disc, { [ellipsis]: !isExpanded })}>
            {replaceMarkdownWithHtmlLinks(description)}
            {' '}
            {isExpanded && !isMobile && (
              <Button className={cn(btnSmall, btnColor)} onClick={() => onReadmoreClick(item)}>
                {t('Show less')}
              </Button>
            )}
          </TextField>
          {!isExpanded && isOverflow && (
            <Button className={cn(btnSmall, btnColor)} onClick={() => onReadmoreClick(item)}>
              {t('Show more')}
            </Button>
          )}
        </div>
      </div>
      <div className={bottom}>
        <TextField size={16}>
          <PriceConverter amount={price} currency={currency ?? 'usd'} />
        </TextField>
        {bottomSection}
      </div>
    </div>
  );
};

const Card = ({ item, onReadmoreClick, isExpanded = false, className, currency, source, itemRef }) => {
  const { cardWrapper, normal, expand, closeAnimation } = useStyles();
  const cardRef = useRef(null);

  const [animationEnd, setAnimationEnd] = useState(isExpanded);

  useEffect(() => {
    const node = cardRef?.current;
    if (!node) return;

    const handleAnimationEnd = () => {
      setAnimationEnd(true);
    };

    node.addEventListener('animationend', handleAnimationEnd);
    return () => {
      node.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [cardRef]);

  return (
    <div
      ref={cardRef}
      className={cn(cardWrapper, className, {
        [normal]: !isExpanded,
        [expand]: isExpanded,
        [closeAnimation]: animationEnd,
      })}
    >
      <CardBody
        item={item}
        onReadmoreClick={onReadmoreClick}
        isExpanded={isExpanded}
        currency={currency}
        source={source}
        cardRef={cardRef}
        itemRef={itemRef}
      />
    </div>
  );
};

export { CardBody };

export default Card;
