/* eslint-disable @guestyci/no-literal-string */
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import createStyles from '@guestyci/foundation/createStyles';
import { Row, Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import Input from '@guestyci/foundation/Input';
import IconButton from '@guestyci/foundation/IconButton';
import { ReactComponent as BtnDetails } from '@guestyci/icons/BtnDetails.svg';
import DateRangePicker from '@guestyci/foundation/DatePicker/DateRangePicker';

import getListings from 'api/listings/getListings';
import MapContainer from './Map';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { DATE_FORMAT } from 'constants/date';
import SearchResults from './SearchResults';

const useStyles = createStyles(() => ({
  root: {
    height: 'calc(100dvh - 321px)',
  },
  listingsContainer: {
    position: 'relative',
    padding: 10,
    flex: '0 1 auto',
    overflow: 'auto',
    width: '100%',
    maxWidth: 550,
    background: 'rgba(255, 255, 255, 0.5)',
    transform: 'translateX(0)',
    '&.isLoading': {
      animation: '$pulse 2s ease-in-out infinite',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&.noResults': {
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&.hidden': {
      transform: 'translateX(-100%)',
      opacity: 0,
      visibility: 'hidden',
      position: 'absolute',
      pointerEvents: 'none',
    },
  },
  mapContainer: {
    position: 'relative',
    flex: '1 1 auto',
  },
  searchContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 20,
    left: 20,
    height: 60,
    borderRadius: 10,
    padding: 10,
    zIndex: 1000,
    gap: 15,
  },
  searchInput: {
    background: '#fff',
  },
  filterSidebar: {
    width: 300,
    height: 'calc(100dvh - 321px)',
    background: 'rgba(255, 255, 255, 0.5)',
    borderRight: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    gap: 10,
  },
  toggleListingsButton: {
    background: '#fff',
  },
  dateRangePicker: {
    background: '#fff',
    borderRadius: 10,
  },
  '@keyframes pulse': {
    '0%': {
      background: '#fff',
    },
    '50%': {
      background: 'rgba(235, 235, 235, 0.9)',
    },
    '100%': {
      background: '#fff',
    },
  },
}));

const FilterSidebar = () => {
  const { filterSidebar } = useStyles();
  return (
    <div className={filterSidebar}>
      <TextField variant="h3">Filters</TextField>
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
      <Input placeholder="Search" />
    </div>
  );
};

function getMarkers(data) {
  if (!data?.results?.length) return [];
  return data.results.reduce((acc, property) => {
    const { address } = property;

    if (!address?.lat || !address?.lng) {
      return acc;
    }

    const EPSILON = 0.000001;
    const foundMarkersIndex = acc.findIndex(({ position }) => Math.abs(address.lat - position.lat) < EPSILON && Math.abs(address.lng - position.lng) < EPSILON);

    if (foundMarkersIndex === -1) {
      acc.push({
        position: { lng: address.lng, lat: address.lat },
        properties: [property],
      });

      return acc;
    }

    acc[foundMarkersIndex].properties.push(property);

    return acc;
  }, []);
}

const useProtectedRoute = () => {
  const { search } = useLocation();
  const { accountData } = useContext(WebsiteSettingsContext);
  const params = new URLSearchParams(search);
  const mapHash = params.get('mapHash');

  // mapHash should be equal to accountData.accountId + "testingMap"
  if (mapHash !== `${accountData.accountId}-testingMap`) {
    window.location.href = '/';
  }
};

const roundBounds = (bounds) => {
  if (!bounds) return null;
  return {
    maxLat: Number(bounds.maxLat.toFixed(3)),
    minLat: Number(bounds.minLat.toFixed(3)),
    maxLng: Number(bounds.maxLng.toFixed(3)),
    minLng: Number(bounds.minLng.toFixed(3)),
  };
};

const SearchPageV2 = () => {
  useProtectedRoute();
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [isListingsShown, setIsListingsShown] = useState(true);
  const { root, listingsContainer, mapContainer, searchContainer, toggleListingsButton, dateRangePicker } = useStyles();
  const [selectedBounds, setSelectedBounds] = useState(null);
  console.log('🚀 ~ SearchPageV2 ~ selectedBounds:', selectedBounds);
  const [hoveredListingId, setHoveredListingId] = useState(null);

  const handleDatesChange = (dates) => {
    setDates(dates);
  };

  const datesKey = dates.startDate && dates.endDate ? `${dates.startDate}-${dates.endDate}` : null;

  const { data, isLoading } = useQuery(
    ['GET_LISTINGS_WITH_BOUNDS', roundBounds(selectedBounds), datesKey],
    () =>
      getListings({
        search: {
          ...roundBounds(selectedBounds),
          limit: 100,
          // tmpForceLimit: 100,
          ...(dates.startDate &&
            dates.endDate && {
              checkIn: dates.startDate.format(DATE_FORMAT),
              checkOut: dates.endDate.format(DATE_FORMAT),
            }),
        },
      }),
    {
      select: (data) => {
        return {
          ...data,
          markers: getMarkers(data),
        };
      },
      enabled: !!selectedBounds,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <Row className={root} justify="between">
      {/* <FilterSidebar /> */}
      <Col
        className={cn(listingsContainer, { hidden: !isListingsShown, isLoading, noResults: !data?.results?.length })}
      >
        <SearchResults listings={data} isLoading={isLoading} onHoverListing={setHoveredListingId} />
      </Col>
      <div className={mapContainer}>
        <Row className={searchContainer}>
          <IconButton
            className={toggleListingsButton}
            variant="filled"
            fill="#fff"
            onClick={() => setIsListingsShown(!isListingsShown)}
          >
            <BtnDetails />
          </IconButton>
          <DateRangePicker className={dateRangePicker} value={dates} onChange={handleDatesChange} />
        </Row>
        <MapContainer setSelectedBounds={setSelectedBounds} listings={data} hoveredListingId={hoveredListingId} />
      </div>
    </Row>
  );
};

export default SearchPageV2;
