import createStyles from '@guestyci/foundation/createStyles';
import Spinner from '@guestyci/foundation/Spinner';
import Divider from '@guestyci/foundation/Divider';

import useGetListingsMarkers from 'hooks/useGetListingsMarkers';
import MapContainer from './MapContainer';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  mapWrapper: {
    height: '100%',
    width: '100%',
    [create('xs')]: {
      display: 'none',
    },
    [create('lg')]: {
      display: 'block',
    },
  },
  mapElement: {
    height: '100%',
    width: '100%',
  },
}));

const Map = ({center, circle, containerStyles, markers: markersProp = null }) => {
  const { mapWrapper } = useStyles();
  const { data: markers, isLoading } = useGetListingsMarkers({ enabled: !circle && !markersProp });

  if (isLoading) return <Spinner />;
  if (circle && (!center?.lat || !center?.lng)) return null;
  return (
    <div className={mapWrapper}>
      <Divider className="mt-6 mb-6" />
      <MapContainer
        loadingElement={<div height="100%" />}
        containerElement={<div height="100%" />}
        mapElement={<div className={mapWrapper} height="100%" />}
        center={center}
        circle={circle}
        markers={markersProp || markers}
        containerStyles={containerStyles}
      />
    </div>
  );
};

export default Map;
