import { useState, useEffect, useCallback } from 'react';

const useIsOverflow = (ref, items = []) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const checkOverflow = useCallback(() => {
    if (!ref?.current) return;
    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = ref.current;
    const hasOverflow = scrollHeight > clientHeight + 10 || scrollWidth > clientWidth + 10;
    setIsOverflow(hasOverflow);
  }, [ref]);

  useEffect(() => {
    if (!ref?.current) return;

    // Run on mount and when items change
    checkOverflow();

    const observer = new ResizeObserver(checkOverflow);
    observer.observe(ref.current);

    const handleResize = () => checkOverflow();
    window.addEventListener('resize', handleResize);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [checkOverflow, ref, items]);

  return isOverflow;
};

export default useIsOverflow;
