import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { DISCO_BE_AUTO_PAYMENT } from 'constants/featureToggleNames';
import Payments from './Payments';
import Holds from './Holds';

const PaymentSchedule = ({ total, currency, className, roundPrice }) => {
  const [, isAutoPayment] = useFeatureToggle(DISCO_BE_AUTO_PAYMENT);

  if (!isAutoPayment) {
    return null;
  }

  return (
    <>
      <Payments roundPrice={roundPrice} className={className} currency={currency} total={total} />
      <Holds roundPrice={roundPrice} total={total} />
    </>
  );
};

export default PaymentSchedule;
