import { useContext, useEffect } from 'react';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import CheckOutInquiry from 'components/CheckOutInquiry';
import CheckOutInstant from 'components/CheckOutInstant';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useDio from 'hooks/useDio';
import bookingTypes from 'constants/bookingTypes';
import useSearchValues from 'hooks/useSearchValues';
import useListingPaymentProvider from 'hooks/useListingPaymentProvider';
import FullPageLoader from 'components/FullPageLoader';
import { useTrackPaymentProviderDiscrepancy } from 'hooks/useDatadogCustomError';
import { BANK_TRANSFER } from 'constants/featureToggleNames';


const Checkout = () => {
  const [, isBankTransferEnabled] = useFeatureToggle(BANK_TRANSFER);
  const {
    bookingType,
    paymentTypes: {
      bankTransfer: bankTransferPaymentTypeEnabled = false,
    } = {},
  } = useContext(WebsiteSettingsContext);
  const { trackError } = useTrackPaymentProviderDiscrepancy();
  const { dioTrack } = useDio();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  dioTrack('view_checkout', 'pageview', {
    type: bookingType,
  });

  const { listingId } = useSearchValues();

  const { isLoading, providerType } = useListingPaymentProvider({ listingId });

  if (isLoading) {
    return <FullPageLoader />;
  }

  const renderFormByType = (type) => {
    switch (type) {
      case bookingTypes.INQUIRY:
        return <CheckOutInquiry />;
      case bookingTypes.INSTANT: {
        const isBankTransferAvailable = isBankTransferEnabled && bankTransferPaymentTypeEnabled;

        // If no payment provider and bank transfer is not available,
        // fallback to inquiry form
        if (!providerType && !isBankTransferAvailable) {
          trackError({
            error: new Error('Payment provider discrepancy'),
            additionalData: {
              type: bookingType,
              listingId,
            },
          });
          return <CheckOutInquiry />;
        }

        return <CheckOutInstant />;
      }
      default:
        return <CheckOutInquiry />;
    }
  };

  return renderFormByType(bookingType);
};

export default Checkout;
