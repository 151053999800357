import { useState } from "react";

import t from "@guestyci/localize/t.macro"
import TextField from "@guestyci/foundation/TextField";
import { Row } from "@guestyci/foundation/Layout";
import createStyles from '@guestyci/foundation/createStyles';
import Divider from '@guestyci/foundation/Divider';

import Amenities from "components/Amenities";

const useStyles = createStyles(() => ({
  showAll: {
    cursor: 'pointer',
    marginTop: 10,
  }
}));


const AmenitiesSection = ({ property = {} } = {}) => {
  const { showAll } = useStyles();

  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const showAmenitiesLabel = showAllAmenities ? t('Show less') : t('Show all');

  const { amenities = [] } = property || {};
  if (!amenities.length) return null;
  return (

    <div>
      <Divider className="mt-6 mb-6" />
      <TextField bold className="mb-2">
        {t('Amenities')}
      </TextField>
      <Row wrap>
        <Amenities showAll={showAllAmenities} amenities={property?.amenities} />
      </Row>
      <TextField onClick={() => setShowAllAmenities((prev) => !prev)} className={showAll} color="link">
        {showAmenitiesLabel}
      </TextField>
    </div>
  );
}

export default AmenitiesSection;