import { useContext } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import map from 'lodash/map';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import { Row } from '@guestyci/foundation/Layout';
import Icon from '@guestyci/foundation/Icon';
import t from '@guestyci/localize/t.macro/t.macro';

import socialIcons from 'constants/socialIcons';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import useLocale from 'hooks/useLocale';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 216,
    height: '100%',
    background: ({ footerBg }) => footerBg || '#212121',
    padding: '20px 0',
  },
  content: {
    width: '100%',
    maxWidth: 625,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: ({ footerTxt }) => footerTxt || '#fff',
  },
  title: {
    color: ({ footerTxt }) => footerTxt || '#fff',
  },
  links: {
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
    justifyContent: 'space-around',
    marginTop: 10,
    '& > li > a': {
      color: ({ footerTxt }) => footerTxt || '#fff',
      cursor: 'pointer',
    },
    '& > li': {
      '&:not(:last-child)': {
        '&::after': {
          content: '"|"',
          color: ({ footerTxt }) => footerTxt || '#fff',
          margin: '0 15px',
          [create('xs')]: {
            display: 'none',
          },
          [create('md')]: {
            display: 'inline',
          },
        },
      },
    },
  },
  socialMedia: {
    width: 140,
    justifyContent: 'space-evenly',
    marginTop: 10,
  },
  phoneNumbers: {
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      color: ({ footerTxt }) => footerTxt || '#fff',
    },
  },
  socialIcon: {
    fill: ({ footerBg }) => footerBg || '#212121',
    '& path': {
      stroke: ({ footerTxt }) => footerTxt || '#fff',
    },
    '& circle': {
      stroke: ({ footerTxt }) => footerTxt || '#fff',
    },
    '& rect': {
      stroke: ({ footerTxt }) => footerTxt || '#fff',
    },
    '& g': {
      '& path': {
        fill: ({ footerTxt }) => footerTxt || '#212121',
      },
    },
  },
}));

const renderPhones = (phones) =>
  phones.map((phone) => (
    <TextField key={phone.number}>
      <a href={`TEL:${phone.number}`}>{phone.number}</a>
    </TextField>
  ));

const renderSocial = (socials, className) => {
  return map(socials, (url, type) => {
    const socialUrl = typeof url === 'object' ? url?.url : url;
    const socialType = typeof url === 'object' ? url.type : type;
    return (
      /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
      <a key={socialUrl} rel="noreferrer" target="_blank" href={`${socialUrl.includes('://') ? '' : '//'}${socialUrl}`}>
        <Icon svg={socialIcons[socialType.toUpperCase()]} size={23} className={className} />
      </a>
    );
  });
};

const Footer = () => {
  const { isTranslationsEnabled } = useGetPathToNavigate();
  const locale = useLocale();
  const path = isTranslationsEnabled ? `/${locale}/privacy-policy` : '/privacy-policy';
  const {
    companyInfo: { address, email, name, phones = [] } = {},
    contentConfiguration: { colorTheme: { colors = {} } = {}, social = null } = {},
  } = useContext(WebsiteSettingsContext);
  const { root, title, content, links, socialMedia, phoneNumbers, socialIcon } = useStyles({
    footerBg: colors?.footerBackground,
    footerTxt: colors?.footerText,
  });
  return (
    <div data-footer className={root}>
      <div className={content}>
        <TextField align="center" variant="h2" className={title}>
          {name}
        </TextField>
        <TextField variant="h5" className="mt-2 pr-4 pl-4">
          {address}
        </TextField>
        <TextField variant="h5" className="mt-2">
          {email}
        </TextField>
        {phones.length > 0 && <div className={cn('mt-2', phoneNumbers)}>{renderPhones(phones)}</div>}

        <ul className={links}>
          <li>
            <Link target="_blank" to={path}>
              {t('Privacy Policy')}
            </Link>
          </li>
        </ul>
        {social && <Row className={socialMedia}>{renderSocial(social, socialIcon)}</Row>}
      </div>
    </div>
  );
};

export default Footer;
