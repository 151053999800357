import { ReactComponent as VisaIcon } from '@guestyci/icons/IcoPaymentsVisa.svg';
import { ReactComponent as AmexIcon } from '@guestyci/icons/IcoPaymentsAmericanExpress.svg';
import { ReactComponent as MasterIcon } from '@guestyci/icons/IcoPaymentsMasterCard.svg';
import { ReactComponent as DinersIcon } from '@guestyci/icons/IcoPaymentsDiners.svg';
import { ReactComponent as JsbIcon } from '@guestyci/icons/IcoPaymentsJCB.svg';
import { ReactComponent as DiscoverIcon } from '@guestyci/icons/IcoPaymentsDiscover.svg';
import { ReactComponent as UnionIcon } from '@guestyci/icons/IcoPaymentsChainaUnionPay.svg';

import { ReactComponent as CMDetector } from './amenities/CM_detector.svg';
import { ReactComponent as AirCondition } from './amenities/air_condition.svg';
import { ReactComponent as Breakfast } from './amenities/breakfast.svg';
import { ReactComponent as Buzzer } from './amenities/buzzer.svg';
import { ReactComponent as Checkin } from './amenities/checkin.svg';
import { ReactComponent as Doorman } from './amenities/doorman.svg';
import { ReactComponent as Dryer } from './amenities/dryer.svg';
import { ReactComponent as Elevator } from './amenities/elevator.svg';
import { ReactComponent as Essentials } from './amenities/essentials.svg';
import { ReactComponent as Events } from './amenities/events.svg';
import { ReactComponent as FamilyFriendly } from './amenities/family_friendly.svg';
import { ReactComponent as FireExtinguisher } from './amenities/fire_extinguisher.svg';
import { ReactComponent as Fireplace } from './amenities/fireplace.svg';
import { ReactComponent as FirstAid } from './amenities/first_aid.svg';
import { ReactComponent as Gym } from './amenities/gym.svg';
import { ReactComponent as HairDryer } from './amenities/hair_dryer.svg';
import { ReactComponent as Hangers } from './amenities/hangers.svg';
import { ReactComponent as Heating } from './amenities/heating.svg';
import { ReactComponent as HotTub } from './amenities/hot_tub.svg';
import { ReactComponent as Iron } from './amenities/iron.svg';
import { ReactComponent as Keypad } from './amenities/keypad.svg';
import { ReactComponent as Kitchen } from './amenities/kitchen.svg';
import { ReactComponent as Laptop } from './amenities/laptop.svg';
import { ReactComponent as Lockbox } from './amenities/lockbox.svg';
import { ReactComponent as Locker } from './amenities/locker.svg';
import { ReactComponent as Parking } from './amenities/parking.svg';
import { ReactComponent as PetsLive } from './amenities/pets_live.svg';
import { ReactComponent as Pool } from './amenities/pool.svg';
import { ReactComponent as PrivateEntrance } from './amenities/private_entrance.svg';
import { ReactComponent as PrivateLivingroom } from './amenities/private_livingroom.svg';
import { ReactComponent as SafetyCard } from './amenities/safety_card.svg';
import { ReactComponent as Shampoo } from './amenities/shampoo.svg';
import { ReactComponent as Smartlock } from './amenities/smartlock.svg';
import { ReactComponent as SmokeDetector } from './amenities/smoke_detector.svg';
import { ReactComponent as Smoking } from './amenities/smoking.svg';
import { ReactComponent as Tv } from './amenities/tv.svg';
import { ReactComponent as Washer } from './amenities/washer.svg';
import { ReactComponent as Wheelchair } from './amenities/wheelchair.svg';
import { ReactComponent as Wifi } from './amenities/wifi.svg';
import { ReactComponent as Cats } from './amenities/cats.svg';
import { ReactComponent as Dogs } from './amenities/dogs.svg';
import { ReactComponent as OtherPets } from './amenities/other_pets.svg';
import { ReactComponent as Internet } from './amenities/internet.svg';
import { ReactComponent as Accessibility } from './amenities/accessibility.svg';
import { ReactComponent as Baby } from './amenities/baby.svg';
import { ReactComponent as Bath } from './amenities/bath.svg';
import { ReactComponent as Bbq } from './amenities/bbq.svg';
import { ReactComponent as Beach } from './amenities/beach.svg';
import { ReactComponent as Bed } from './amenities/bed.svg';
import { ReactComponent as Chair } from './amenities/chair.svg';
import { ReactComponent as Cleaning } from './amenities/cleaning.svg';
import { ReactComponent as Coffee } from './amenities/coffee.svg';
import { ReactComponent as Console } from './amenities/console.svg';
import { ReactComponent as Dishes } from './amenities/dishes.svg';
import { ReactComponent as Dishwasher } from './amenities/dishwasher.svg';
import { ReactComponent as Ev } from './amenities/ev.svg';
import { ReactComponent as Garden } from './amenities/garden.svg';
import { ReactComponent as Guard } from './amenities/guard.svg';
import { ReactComponent as HotWater } from './amenities/hot_water.svg';
import { ReactComponent as LongTerm } from './amenities/long_term.svg';
import { ReactComponent as Luggage } from './amenities/luggage.svg';
import { ReactComponent as Microwave } from './amenities/microwave.svg';
import { ReactComponent as Oven } from './amenities/oven.svg';
import { ReactComponent as Patio } from './amenities/patio.svg';
import { ReactComponent as Refrigerator } from './amenities/refrigerator.svg';
import { ReactComponent as Shades } from './amenities/shades.svg';
import { ReactComponent as Stove } from './amenities/stove.svg';
import { ReactComponent as Table } from './amenities/table.svg';
import { ReactComponent as ListingGuests } from './hotelFeatures/listing_guests.svg';
import { ReactComponent as ListingBedrooms } from './hotelFeatures/listing_bedrooms.svg';
import { ReactComponent as ListingBeds } from './hotelFeatures/listing_beds.svg';
import { ReactComponent as ListingBathroom } from './hotelFeatures/listing_bathroom.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as StarFilled } from './star_filled.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Coupon } from './coupon.svg';
import { ReactComponent as Location } from './locationMark.svg'
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as ArrowNext } from './arrow_next.svg';
import { ReactComponent as Guest } from './guest.svg';
import { ReactComponent as Hotel } from './hotel.svg';
import { ReactComponent as InquirySuccess } from './inquiry_success.svg';
import { ReactComponent as InstantSuccess } from './instant_success.svg';
import { ReactComponent as NoResults } from './no_results.svg';
import { ReactComponent as NotFound } from './not_found.svg';
import { ReactComponent as PetsAllowed } from './houseRules/petsAllowed.svg';
import { ReactComponent as QuietBetween } from './houseRules/quietBetween.svg';
import { ReactComponent as SmokingAllowed } from './houseRules/smokingAllowed.svg';
import { ReactComponent as SuitableForEvents } from './houseRules/suitableForEvents.svg';
import { ReactComponent as SuitableForChildren } from './houseRules/suitableForChildren.svg';
import { ReactComponent as SuitableForInfants } from './houseRules/suitableForInfants.svg';
import { ReactComponent as NotPetsAllowed } from './houseRules/notPetsAllowed.svg';
import { ReactComponent as NotQuietBetween } from './houseRules/notQuietBetween.svg';
import { ReactComponent as NotSmokingAllowed } from './houseRules/notSmokingAllowed.svg';
import { ReactComponent as NotSuitableForEvents } from './houseRules/notSuitableForEvents.svg';
import { ReactComponent as NotSuitableForChildren } from './houseRules/notSuitableForChildren.svg';
import { ReactComponent as NotSuitableForInfants } from './houseRules/notSuitableForInfants.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Youtube } from './youtube.svg';


const AMENITIES = {
  CM_detector: CMDetector,
  air_condition: AirCondition,
  breakfast: Breakfast,
  buzzer: Buzzer,
  checkin: Checkin,
  doorman: Doorman,
  dryer: Dryer,
  elevator: Elevator,
  essentials: Essentials,
  events: Events,
  family_friendly: FamilyFriendly,
  fire_extinguisher: FireExtinguisher,
  fireplace: Fireplace,
  first_aid: FirstAid,
  gym: Gym,
  hair_dryer: HairDryer,
  hangers: Hangers,
  heating: Heating,
  hot_tub: HotTub,
  iron: Iron,
  keypad: Keypad,
  kitchen: Kitchen,
  laptop: Laptop,
  lockbox: Lockbox,
  locker: Locker,
  parking: Parking,
  pets_allowed: PetsAllowed,
  pets_live: PetsLive,
  pool: Pool,
  private_entrance: PrivateEntrance,
  private_livingroom: PrivateLivingroom,
  safety_card: SafetyCard,
  shampoo: Shampoo,
  smartlock: Smartlock,
  smoke_detector: SmokeDetector,
  smoking: Smoking,
  tv: Tv,
  washer: Washer,
  wheelchair: Wheelchair,
  wifi: Wifi,
  cats: Cats,
  dogs: Dogs,
  other_pets: OtherPets,
  internet: Internet,
  accessibility: Accessibility,
  baby: Baby,
  bath: Bath,
  bbq: Bbq,
  beach: Beach,
  bed: Bed,
  chair: Chair,
  cleaning: Cleaning,
  coffee: Coffee,
  console: Console,
  dishes: Dishes,
  dishwasher: Dishwasher,
  ev: Ev,
  garden: Garden,
  guard: Guard,
  hot_water: HotWater,
  long_term: LongTerm,
  luggage: Luggage,
  microwave: Microwave,
  oven: Oven,
  patio: Patio,
  refrigerator: Refrigerator,
  shades: Shades,
  stove: Stove,
  table: Table,
};

const HOTEL_FEATURES = {
  listing_guests: ListingGuests,
  listing_bedrooms: ListingBedrooms,
  listing_beds: ListingBeds,
  listing_bathroom: ListingBathroom,
};

const COMMON = {
  star: Star,
  star_filled: StarFilled,
  close: Close,
  coupon: Coupon,
  location: Location,
  lock: Lock,
  arrow: Arrow,
  arrow_next: ArrowNext,
  guest: Guest,
  hotel: Hotel,
  inquiry_success: InquirySuccess,
  instant_success: InstantSuccess,
  no_results: NoResults,
  not_found: NotFound,
};

const HOUSE_RULES = {
  petsAllowed: PetsAllowed,
  quietBetween: QuietBetween,
  smokingAllowed: SmokingAllowed,
  suitableForEvents: SuitableForEvents,
  suitableForChildren: SuitableForChildren,
  suitableForInfants: SuitableForInfants,
  notPetsAllowed: NotPetsAllowed,
  notQuietBetween: NotQuietBetween,
  notSmokingAllowed: NotSmokingAllowed,
  notSuitableForEvents: NotSuitableForEvents,
  notSuitableForChildren: NotSuitableForChildren,
  notSuitableForInfants: NotSuitableForInfants,
};

const SOCIAL_MEDIA = {
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  youtube: Youtube,
};

const CREDIT_CARDS = {
  visa: VisaIcon,
  mastercard: MasterIcon,
  jcb: JsbIcon,
  diners: DinersIcon,
  unionpay: UnionIcon,
  discover: DiscoverIcon,
  amex: AmexIcon,
};

export const iconMap = {
  ...COMMON,
  ...HOTEL_FEATURES,
  ...AMENITIES,
  ...CREDIT_CARDS,
  ...SOCIAL_MEDIA,
  ...HOUSE_RULES,
};

export default iconMap;
