import { datadogRum } from '@datadog/browser-rum';

import useSearchValues from './useSearchValues';
import useDio from './useDio';


const CREATE_RESERVATION_ERROR_TYPE = 'Create reservation error';
const PAYMENT_PROVIDER_DISCREPANCY_ERROR_TYPE = 'Payment provider discrepancy error';

export const useTrackCreateReservationError = ({ property, type, provider }) => {
  const { dioTrack } = useDio();
  const { minOccupancy } = useSearchValues();

  const trackError = ({ error, additionalData }) => {
    const errorData = {
      error,
      type,
      provider,
      ...additionalData,
      listings: [
        {
          guests: minOccupancy,
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
        },
      ],
    }
    datadogRum.addError(error, {
      ...errorData,
      errorType: CREATE_RESERVATION_ERROR_TYPE,
    });
    dioTrack('booking_create_error', 'side-effect', errorData);
  };
  return { trackError };
};

export const useTrackPaymentProviderDiscrepancy = () => {
  const trackError = ({ error, additionalData }) => {
    datadogRum.addError(error, {
      ...additionalData,
      errorType: PAYMENT_PROVIDER_DISCREPANCY_ERROR_TYPE,
    })
  }

  return { trackError };
}
