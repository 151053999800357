/* eslint-disable @guestyci/no-literal-string */
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import { Row, Col } from "@guestyci/foundation/Layout";
import Spinner from '@guestyci/foundation/Spinner';
import TextField from "@guestyci/foundation/TextField";
import t from '@guestyci/localize/t.macro';

import Icon from 'components/Icon';
import PriceConverter from "components/PriceConverter";

const useStyles = createStyles(() => ({
  root: {
    gap: 10,
    minHeight: 150,
    background: '#fff',
    borderRadius: 10,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: 10,
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
    }
  },
  textContainer: {
    padding: 10,
  },
  titleContainer: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: 14,
    fontWeight: 600,
  },
  descriptionContainer: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },

}));

const FALLBACK_IMAGE = `data:image/svg+xml,${encodeURIComponent(`
  <svg width="100%" height="100%" viewBox="0 0 330 180" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" fill="#f0f0f0"/>
    <text x="50%" y="50%" font-family="Arial" font-size="10" fill="#666" text-anchor="middle" dominant-baseline="middle">
      No image available
    </text>
  </svg>
  `)}`;

const SearchResults = ({ listings, isLoading, onHoverListing }) => {
  const { root, pictureContainer, textContainer, titleContainer, descriptionContainer } = useStyles();

  if (isLoading) return <Spinner />
  if (!listings?.results?.length) return (
    <Col justify="center" align="center">
      <Icon icon="no_results" size={200} />
      <TextField>No results found...</TextField>
    </Col>
  );
  return listings.results.map((listing) => {
    return (
      <Row
        key={listing._id}
        className={cn(root, { isLoading })}
        onMouseEnter={() => onHoverListing(listing._id)}
        onMouseLeave={() => onHoverListing(null)}
      >
        <Col className={pictureContainer}>
          <img
            style={{ width: '100px', height: '100%', objectFit: 'cover' }}
            src={listing?.picture?.thumbnail}
            alt={listing?.title || listing?.nickname}
            onError={(e) => {
              e.target.src = FALLBACK_IMAGE
            }}
          />
        </Col>
        <Col className={textContainer} justify="between">
          <TextField variant="h2" className={titleContainer}>{listing?.title || listing?.nickname}</TextField>
          <TextField variant="h5" className={descriptionContainer} color="placeholder">{listing?.publicDescription?.summary}</TextField>
          <Row align="center">
            {t('{price}  <muted>Night</muted>', {
              price: <PriceConverter currency={listing?.prices?.currency} amount={listing?.prices?.basePrice} />,
              muted: chunks => <TextField variant="h5" color="placeholder">&nbsp;{chunks}</TextField>,
            })}
          </Row>
        </Col>
      </Row>
    );
  });
}

export default SearchResults;