import { useEffect, useMemo } from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import createStyles from '@guestyci/foundation/createStyles';
import Divider from '@guestyci/foundation/Divider';
import TextField from '@guestyci/foundation/TextField';
import { Row, Col } from '@guestyci/foundation/Layout';
import Spinner from '@guestyci/foundation/Spinner';
import t from '@guestyci/localize/t.macro/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import CouponCode from 'components/CouponCode';
import Promotion from 'components/Promotion';
import { RatePlan } from 'components/RatePlan';
import PriceConverter from 'components/PriceConverter';
import DatesAndGuests from 'components/DatesAndGuests';
import PriceInfo from 'components/PriceInfo';
import Image from 'components/Image/Image';
import { UpsellCheckOutItems } from 'components/UpsellCarousel';
import useGetListingById from 'hooks/useGetListingById';
import useSearchValues from 'hooks/useSearchValues';
import useDio from 'hooks/useDio';
import useBookNowButtonText from 'hooks/useBookNowButtonText';
import { DISCOUNT_TYPE, INVOICE_ITEM_TYPE } from 'constants/constants';
import useGetQuote from 'hooks/useGetQuote';
import { useUpsellContext } from 'context/UpsellContext';
import { UPSELL } from 'constants/featureToggleNames';
import PaymentSchedule from 'components/PaymentSchedule';
import FirstPaymentDate from 'components/PaymentSchedule/FirstPaymentDate';
import OriginalPrice from 'components/OriginalPrice';

const useStyles = createStyles(({ breakpoints: { create }, boxShadow }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 394,
    overflow: 'hidden',
    boxShadow: boxShadow[1],
    borderRadius: 20,
    [create('xs')]: {
      marginTop: 40,
    },
  },
  contentWrapper: {
    padding: 30,
    [create('xs')]: {
      padding: 15,
    },
  },
  image: {
    '& > img': {
      width: '100%',
      height: 240,
      objectFit: 'cover',
    },
  },
  title: {
    fontSize: 30,
    fontWeight: '600',
    [create('xs')]: {
      fontSize: 24,
    },
  },
  dates: {
    display: 'flex',
    marginBottom: 10,
    [create('xs')]: {},
  },
  dateBlock: {
    '&:first-child': {
      marginRight: 30,
    },
  },
  date: {
    fontSize: 16,
  },
  guests: {
    marginBottom: 10,
  },
  rooms: {
    marginBottom: 10,
  },
  nightsWithPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  ratePlan: {
    marginBottom: 10,
  },
  promotion: {
    marginTop: 10,
    marginBottom: 10,
  },
  oldSubTotal: {
    textDecoration: 'line-through',
  },
  submitButton: {
    fontSize: 18,
    marginTop: 40,
    width: '100%',
  },
  paymentSchedule: {
    marginBottom: -20,
  },
}));

const CheckOutSummary = ({ submit, isLoading, isFormInvalid, onChangePriceAmount, roundPrice = 2 }) => {
  const { FIXED } = DISCOUNT_TYPE;
  const { DISCOUNT } = INVOICE_ITEM_TYPE;
  const { root, contentWrapper, image, title, ratePlan, promotion, submitButton, paymentSchedule } = useStyles();
  const {
    state: { quoteId, ratePlanId },
  } = useLocation();
  const { dioTrack } = useDio();
  const { id } = useParams();
  const { startDate, endDate, minOccupancy } = useSearchValues();
  const bookNowButtonText = useBookNowButtonText();

  const { upsell } = useUpsellContext();
  const [, isFTUpsellEnabled] = useFeatureToggle(UPSELL);

  const { property, isGettingProperty, isGettingPropertyError, isSuccess } = useGetListingById({ id });

  const { data: quote, isLoading: isGettingQuote, isError: isGettingQuoteError, refetch } = useGetQuote(quoteId);

  const { money, selectedRatePlan } = useMemo(() => {
    if (isGettingQuote || isGettingQuoteError || isGettingProperty || isGettingPropertyError) {
      return {
        money: null,
        selectedRatePlan: null,
      };
    }

    const plans = quote?.rates?.ratePlans;

    const selectedRatePlanMemoized = find(plans, {
      ratePlan: { _id: ratePlanId },
    }).ratePlan;

    const { money: moneyMemoized } = selectedRatePlanMemoized;

    return {
      money: moneyMemoized,
      selectedRatePlan: selectedRatePlanMemoized,
    };
  }, [
    isGettingProperty,
    isGettingPropertyError,
    isGettingQuote,
    isGettingQuoteError,
    quote?.rates?.ratePlans,
    ratePlanId,
  ]);

  useEffect(() => {
    if (onChangePriceAmount) {
      onChangePriceAmount(money?.hostPayout);
    }
  }, [money?.hostPayout, onChangePriceAmount]);

  useEffect(() => {
    refetch();
  }, [upsell]);

  if (isGettingProperty || isGettingQuote) {
    return (
      <div className={root}>
        <Spinner />
      </div>
    );
  }

  if (isGettingPropertyError || isGettingQuoteError) {
    return (
      <div className={root}>
        <h1>{t('Error')}</h1>
      </div>
    );
  }

  const { fareAccommodationAdjusted, currency, hostPayout, fareAccommodation, totalFees, totalTaxes, invoiceItems } =
    money || {};

  const appliedCoupons = invoiceItems.filter((item) => item.type === DISCOUNT);

  const handleTrackBooking = () => {
    const metadata = {
      listings: [
        {
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
        },
      ],
    };

    if (isFTUpsellEnabled) {
      metadata.upsells = {
        list: upsell?.selectedUpsells,
        total: upsell?.selectedUpsells?.reduce((acc, el) => acc + el.price, 0),
      };
    }

    dioTrack('click_checkout', 'click', metadata);
  };

  const isShowSelectedRatePlan = selectedRatePlan && selectedRatePlan?.cancellationPolicy;
  const showOriginalPrice = fareAccommodation > fareAccommodationAdjusted;

  return (
    isSuccess && (
      <div className={root}>
        <div className={image}>
          <Image alt="property" src={property?.pictures[0]?.original || property.picture?.thumbnail} h={240} />
        </div>
        <div className={contentWrapper}>
          <TextField className={title}>{property.title}</TextField>
          <DatesAndGuests startDate={startDate} endDate={endDate} guestsCount={minOccupancy} />
          {isShowSelectedRatePlan && (
            <>
              <div className={ratePlan}>
                <RatePlan ratePlan={selectedRatePlan} defaultCheckInTime={property.defaultCheckInTime} />
              </div>
              <Divider />
            </>
          )}
          <Promotion promotion={quote.promotions} className={promotion} />
          {quote?.coupons.map((coupon) => {
            const discountType = coupon.discountType?.toUpperCase();
            const appliedCoupon = appliedCoupons.find(({ title: appliedCouponTitle }) => appliedCouponTitle === coupon?.name);
            const actualCouponData = {
              ...coupon,
              discount: discountType === FIXED ? -appliedCoupon?.amount : coupon?.discount,
              discountType,
              currency: appliedCoupon?.currency,
            };

            return <CouponCode roundPrice={roundPrice} coupon={actualCouponData} actualDiscount={appliedCoupon} />;
          })}
          <Divider />
          <UpsellCheckOutItems roundPrice={roundPrice} currency={property?.prices?.currency} />
          <Row className="pb-2 pt-2" justify="between" align="end">
            <TextField bold>{t('Subtotal')}</TextField>
            <Col align="center">
              {showOriginalPrice && <OriginalPrice roundPrice={roundPrice} money={{ currency, fareAccommodation }} />}
              <TextField bold>
                <PriceConverter roundPrice={roundPrice} amount={fareAccommodationAdjusted} currency={currency} />
              </TextField>
            </Col>
          </Row>
          <Divider />
          <Row justify="between" className="mt-2 mb-2">
            <TextField>{t('Fees')}</TextField>
            <TextField>
              <PriceConverter roundPrice={roundPrice} amount={totalFees} currency={currency} />
            </TextField>
          </Row>
          <Row justify="between" className="mb-2">
            <TextField>{t('Taxes')}</TextField>
            <TextField>
              <PriceConverter roundPrice={roundPrice} amount={totalTaxes} currency={currency} />
            </TextField>
          </Row>
          <Divider />
          <Row className="pb-2 pt-2" justify="between" align="end">
            <TextField bold variant="h3">
              {t('Total')}
            </TextField>
            <Col align="center">
              <TextField data-qa="total-price" bold variant="h3">
                <PriceConverter roundPrice={roundPrice} amount={hostPayout} currency={currency} />
              </TextField>
            </Col>
          </Row>
          <PriceInfo roundPrice={roundPrice} ratePlan={selectedRatePlan} />
          <PaymentSchedule roundPrice={roundPrice} currency={currency} className={paymentSchedule} total={hostPayout} />
          <button
            data-qa="submit"
            type="submit"
            className={cn('btn btn-colored', submitButton)}
            disabled={isLoading || isFormInvalid}
            onClick={(event) => {
              handleTrackBooking();
              submit.current(event);
            }}
          >
            {isLoading ? <Spinner /> : bookNowButtonText}
          </button>
          <FirstPaymentDate total={hostPayout} />
        </div>
      </div>
    )
  );
};

export default CheckOutSummary;
