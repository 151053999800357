import { useEffect, useRef, useState, useMemo } from 'react';
import cn from 'classnames';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

import PriceConverter from 'components/PriceConverter';


const useStyles = createStyles(() => ({
  root: {
    minWidth: 30,
    height: 28,
    borderRadius: 28,
    padding: [0, 8],
    background: '#fff',
    border: '1px solid #000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1,
    transition: 'transform 0.3s ease',
    '&.hovered:not(.open)': {
      zIndex: 2,
      border: '2px solid #000',
      animation: '$bounce 0.5s ease infinite',
    },
    '&.open': {
      zIndex: 2,
      border: '2px solid #000',
      '& $pinRoot': {}
    },
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
  },
  pinRoot: {
    // position: 'absolute',
    transition: 'all 0.3s ease',
    // transform: 'translateX(-50%) translateY(20px)',
  },
  listingDetails: {
    position: 'absolute',
    bottom: 40, // Position above the marker
    left: '50%', // Center horizontally
    transform: 'translateX(-50%)', // Offset by half width to truly center
    width: 330,
    height: 280,
    background: '#fff',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    overflow: 'hidden',
  },
  imageContainer: {
    width: '100%',
    height: 180,
    background: '#000',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }
  },
  detailsContainer: {
    padding: 10,
    flex: '1 1 auto',
  },
  titleContainer: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: 14,
    fontWeight: 600,
  },
  descriptionContainer: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
}));

const FALLBACK_IMAGE = `data:image/svg+xml,${encodeURIComponent(`
  <svg width="100%" height="100%" viewBox="0 0 330 180" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="100%" fill="#f0f0f0"/>
    <text x="50%" y="50%" font-family="Arial" font-size="10" fill="#666" text-anchor="middle" dominant-baseline="middle">
      No image available
    </text>
  </svg>
  `)}`;

const ListingDetails = ({ properties, isOpen }) => {
  const { listingDetails, imageContainer, titleContainer, detailsContainer } = useStyles();
  if (!isOpen || !properties?.length) return null;
  const firstProperty = properties[0];

  return (
    <Col className={listingDetails}>
      <div className={imageContainer}>
        <img
          src={firstProperty?.picture?.thumbnail || FALLBACK_IMAGE}
          alt={firstProperty?.title || firstProperty?.nickname || 'Property'}
          onError={(e) => { e.target.src = FALLBACK_IMAGE }}
        />
      </div>
      <Col className={detailsContainer} justify="between">
        <TextField variant="h2" className={titleContainer}>{firstProperty?.title || firstProperty?.nickname}</TextField>
        <Row align="center">
          {t('{price}  <muted>Night</muted>', {
            price: <PriceConverter currency={firstProperty?.prices?.currency} amount={firstProperty?.totalPrice || firstProperty?.prices?.basePrice} />,
            muted: chunks => <TextField variant="h5" color="placeholder">&nbsp;{chunks}</TextField>,
          })}
        </Row>
      </Col>
    </Col>
  )
}


const Marker = ({ properties, isOpen }) => {
  const { pinRoot } = useStyles();
  if (!properties?.length) return null;

  const firstProperty = properties[0];
  const lastProperty = properties[properties.length - 1];
  const firstPrice = firstProperty?.totalPrice || firstProperty?.prices?.basePrice;
  const lastPrice = lastProperty?.totalPrice || lastProperty?.prices?.basePrice;

  if (!firstPrice) return null;

  return (
    <div className={pinRoot}>
      <PriceConverter currency={firstProperty?.prices?.currency} amount={firstPrice} />
      {properties.length > 1 && lastPrice && (
        <>
          -
          <PriceConverter currency={lastProperty?.prices?.currency} amount={lastPrice} />
        </>
      )}
      <ListingDetails properties={properties} isOpen={isOpen} />
    </div>
  )
}

const CustomAdvancedMarker = ({ marker, hoveredListingId }) => {
  const { root } = useStyles()
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const { properties, position } = marker;

  const isHighlighted = useMemo(
    () =>
      properties.some(prop => prop._id === hoveredListingId),
    [properties, hoveredListingId]
  );

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  return (
    <>
      <AdvancedMarker
        position={{ lng: position.lng, lat: position.lat }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => setIsOpen(!isOpen)}
        ref={containerRef}
        zIndex={isOpen ? 9999 : (hovered ? 9998 : (isHighlighted ? 9998 : 1))}
        className={cn(root, {
          'hovered': hovered || isHighlighted,
          'open': isOpen,
        })}
      >
        <Marker properties={properties} isOpen={isOpen} hovered={hovered || isHighlighted} />
      </AdvancedMarker>
    </>
  )
}

export default CustomAdvancedMarker;