import { Col } from "@guestyci/foundation/Layout";
import TextField from "@guestyci/foundation/TextField";
import t from "@guestyci/localize/t.macro";
import Divider from "@guestyci/foundation/Divider";

const FullDescriptionSection = ({ property = {} } = {}) => {
  const { fullDescription = null } = property || {};
  if (!fullDescription) return null;
  return (
    <Col>
      <Divider className="mt-6 mb-6" />
      <TextField bold className="mb-2">
        {t('Description')}
      </TextField>
      <TextField className="mb-6">{property?.fullDescription}</TextField>
    </Col>
  );
}

export default FullDescriptionSection;