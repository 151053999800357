import { useHistory } from 'react-router-dom';

import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';
import createStyles from '@guestyci/foundation/createStyles';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { CREDIT_CARD_PAYMENT_TYPE } from 'constants/constants';
import { FORTER_3DS, ZERO_AMOUNT_NOTIFICATION } from 'constants/featureToggleNames';

const useStyles = createStyles(({ palette }) => ({
  wrapper: {
    marginTop: 15,
    backgroundColor: palette.success.background,
    padding: 15,
    maxWidth: 480,
    width: '100%',
    borderRadius: 4,
  },
}));

const ChargeNotice = () => {
  const { wrapper } = useStyles();
  const history = useHistory();
  const paymentType = history?.location?.state?.paymentType || CREDIT_CARD_PAYMENT_TYPE;
  const instantCharge = history?.location?.state?.instantCharge;
  const isCreditCardType = paymentType === CREDIT_CARD_PAYMENT_TYPE;

  const [, isZeroAmountNotificationEnabled] = useFeatureToggle(ZERO_AMOUNT_NOTIFICATION);
  const [, isForter3DSEnabled] = useFeatureToggle(FORTER_3DS);

  const featuresEnabled = isZeroAmountNotificationEnabled || isForter3DSEnabled;

  if (!isCreditCardType || !featuresEnabled || instantCharge) {
    return null;
  }

  return (
    <div className={wrapper}>
      <TextField color="dark">
        {isForter3DSEnabled
          ? t("We'll shortly email you the reservation confirmation. You may need to confirm the payment through your bank, in which case we will send another email for you to confirm the payment.")
          : t('Your card will be automatically charged according to our payment policies in up to 30 minutes.')}
      </TextField>
    </div>
  );
};

export default ChargeNotice;
